import { template as template_1a67d2bbb26240b5ad435a1f26e70e61 } from "@ember/template-compiler";
const FKLabel = template_1a67d2bbb26240b5ad435a1f26e70e61(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
