import { template as template_b3e67854330a4e02859c6ad15d79f607 } from "@ember/template-compiler";
const SidebarSectionMessage = template_b3e67854330a4e02859c6ad15d79f607(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
